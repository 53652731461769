import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-date-range"
export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.onChange.bind(this));
    this.onChange(false);
  }

  onChange(submit = true) {
    const value = this.element.value;

    if (value === "custom") {
      document.querySelectorAll(".custom-date").forEach((el) => {
        el.classList.remove("d-none");
      });
    }
    else {
      document.querySelectorAll(".custom-date").forEach((el) => {
        el.classList.add("d-none");
      });

      if (submit) {
        this.element.form.requestSubmit();
      }
    }
  }
}
