import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="facebook-feed"
export default class extends Controller {
  connect() {
    const padding = 32; // 2rem (assuming 1rem = 16px)
    const maxWidth = 500;
    const screenWidth = window.innerWidth
    const fbPageUrl = "https://www.facebook.com/FuchsFinancial";

    let iframeWidth

    if (screenWidth <= (maxWidth + padding)) {
      iframeWidth = screenWidth - padding
    } else {
      iframeWidth = maxWidth
    }

    const fbIframeSrc = `https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(fbPageUrl)}&tabs=timeline&width=${iframeWidth}&height=700&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId`

    const iframe = document.createElement('iframe')
    iframe.src = fbIframeSrc
    iframe.width = iframeWidth
    iframe.height = "700"
    iframe.style.border = "none"
    iframe.style.overflow = "hidden"
    iframe.scrolling = "no"
    iframe.frameBorder = "0"
    iframe.allowFullscreen = "true"
    iframe.allow = "autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    iframe.title = "Fuchs Financial Facebook Page" // Accessibility

    this.element.appendChild(iframe)
  }
}